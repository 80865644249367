// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Project from "../../../../models/Project.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as UserContact from "../../../../models/UserContact.res.js";
import * as RailsContext from "../../../../models/RailsContext.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as CreateNetworkProjectGeneralInformation from "./CreateNetworkProjectGeneralInformation.res.js";

function CreateNetworkProjectGeneralInformation__JsBridge$default(props) {
  var railsContext = props.railsContext;
  var networkPrivateLineConfigurations = props.networkPrivateLineConfigurations;
  var networkMplsConfigurations = props.networkMplsConfigurations;
  var networkSdWanConfigurations = props.networkSdWanConfigurations;
  var status = props.status;
  var contacts = props.contacts;
  var documents = props.documents;
  var businessPhone = props.businessPhone;
  var businessEmail = props.businessEmail;
  var headquartersAddress = props.headquartersAddress;
  var businessContactName = props.businessContactName;
  var businessName = props.businessName;
  var isAgent = props.isAgent;
  var description = props.description;
  var title = props.title;
  var subcategory = props.subcategory;
  var category = props.category;
  var projectId = props.projectId;
  var railsContext$1 = React.useMemo((function () {
          return RailsContext.fromJson(railsContext);
        }), [railsContext]);
  var projectId$1 = React.useMemo((function () {
          return Belt_Result.getExn(Json_Decode$JsonCombinators.decode(projectId, Json_Decode$JsonCombinators.option(Project.Id.decoder)));
        }), [projectId]);
  var category$1 = React.useMemo((function () {
          return Project.Category.fromString(Belt_Result.getExn(Json_Decode$JsonCombinators.decode(category, Json_Decode$JsonCombinators.string)), Belt_Result.getExn(Json_Decode$JsonCombinators.decode(subcategory, Json_Decode$JsonCombinators.string)));
        }), [
        category,
        subcategory
      ]);
  var title$1 = React.useMemo((function () {
          return Belt_Result.getExn(Json_Decode$JsonCombinators.decode(title, Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)));
        }), [title]);
  var description$1 = React.useMemo((function () {
          return Belt_Result.getExn(Json_Decode$JsonCombinators.decode(description, Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)));
        }), [description]);
  var alreadyUploadedDocuments = React.useMemo((function () {
          return Belt_Result.getExn(Json_Decode$JsonCombinators.decode(documents, Json_Decode$JsonCombinators.array(Project.$$Document.decoder)));
        }), [documents]);
  var businessName$1 = React.useMemo((function () {
          return Belt_Option.getWithDefault(Belt_Result.getExn(Json_Decode$JsonCombinators.decode(businessName, Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))), "");
        }), [businessName]);
  var businessContactName$1 = React.useMemo((function () {
          return Belt_Option.getWithDefault(Belt_Result.getExn(Json_Decode$JsonCombinators.decode(businessContactName, Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))), "");
        }), [businessContactName]);
  var businessEmail$1 = React.useMemo((function () {
          return Belt_Option.getWithDefault(Belt_Result.getExn(Json_Decode$JsonCombinators.decode(businessEmail, Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))), "");
        }), [businessEmail]);
  var businessPhone$1 = React.useMemo((function () {
          return Belt_Option.getWithDefault(Belt_Result.getExn(Json_Decode$JsonCombinators.decode(businessPhone, Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))), "");
        }), [businessPhone]);
  var headquartersAddress$1 = React.useMemo((function () {
          return Belt_Option.getWithDefault(Belt_Result.getExn(Json_Decode$JsonCombinators.decode(headquartersAddress, Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))), "");
        }), [headquartersAddress]);
  var contacts$1 = React.useMemo((function () {
          return Belt_Result.getExn(Json_Decode$JsonCombinators.decode(contacts, Json_Decode$JsonCombinators.array(UserContact.decoder)));
        }), [contacts]);
  var isAgent$1 = React.useMemo((function () {
          return Belt_Result.getExn(Json_Decode$JsonCombinators.decode(isAgent, Json_Decode$JsonCombinators.bool));
        }), [isAgent]);
  var configurations = React.useMemo((function () {
          var configurations = networkSdWanConfigurations !== undefined ? networkSdWanConfigurations : (
              networkMplsConfigurations !== undefined ? networkMplsConfigurations : (
                  networkPrivateLineConfigurations !== undefined ? networkPrivateLineConfigurations : PervasivesU.failwith("No configurations found")
                )
            );
          return Belt_Array.map(configurations, CreateNetworkProjectGeneralInformation.Configuration.fromJson);
        }), []);
  var status$1 = React.useMemo((function () {
          return Project.Status.fromString(status);
        }), [status]);
  return JsxRuntime.jsx(CreateNetworkProjectGeneralInformation.make, {
              userLoginStatus: railsContext$1.userLoginStatus,
              userRole: railsContext$1.userRole,
              projectId: projectId$1,
              category: category$1,
              title: title$1,
              configurations: configurations,
              description: description$1,
              alreadyUploadedDocuments: alreadyUploadedDocuments,
              businessName: businessName$1,
              businessContactName: businessContactName$1,
              businessEmail: businessEmail$1,
              businessPhone: businessPhone$1,
              headquartersAddress: headquartersAddress$1,
              contacts: contacts$1,
              isAgent: isAgent$1,
              mobile: railsContext$1.mobile,
              status: status$1
            });
}

var $$default = CreateNetworkProjectGeneralInformation__JsBridge$default;

export {
  $$default as default,
}
/* react Not a pure module */
